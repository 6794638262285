import React from 'react'
import InputMessageBottom from './InputMessageBottom'
import { InputProps, Input as AntInput } from 'antd'
import Box, { BoxProps } from './Box'
import { FormikValues } from 'formik'
import { getInputProps } from '../../utils/fields'
import InputLabel from './InputLabel'

interface Props extends Omit<InputProps, 'form'> {
  message?: React.ReactNode
  error?: boolean
  form?: FormikValues
  label?: string
  boxProps?: Partial<BoxProps>
}

const Input = ({ error, message, form, label, boxProps, ...props }: Props) => {
  const commonProps = form && props.name ? getInputProps(form, props.name) : {}

  return (
    <Box {...boxProps}>
      {label && <InputLabel>{label}</InputLabel>}
      <AntInput
        {...commonProps}
        {...props}
        status={error || commonProps.error ? 'error' : undefined}
      />
      <InputMessageBottom
        error={commonProps.error || error}
        message={commonProps.message || message}
      />
    </Box>
  )
}

export default Input
