export interface IdName<T = number | string> {
  id: T
  name: string
}

export enum SelectorKey {
  currencies = 'currencies',
  pricing_periods = 'pricing_periods',
  material_icons = 'material_icons',
  comparison_table_item_type = 'comparison_table_item_type',
  countries = 'countries',
}

export enum Cookies {
  guest_email = 'guest_email',
}

export type PaidFeatureType = 'ab' | 'pricesByCountry'
