import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import { BoldBlue } from '@components/Landing/Common'
import routes from '@routes'
import Flex from '@ui/Flex'
import TryButton from '@components/Landing/TryButton'

type Props = {}

const Wrap = styled(Flex)`
  margin-bottom: 200px;
  background: url('/img/landing/pricing-tables-bg.png') no-repeat center / cover;
  background-attachment: fixed;
  width: 100%;
  height: 900px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
  }

  @media (max-width: 960px) {
    flex-direction: column;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 40px;
    height: 800px;
  }
`

const TryButtonStyled = styled(TryButton)`
  margin-top: 0px;

  @media (max-width: 1035px) {
    margin-top: 45px;
  }
`

const Title = styled(Box)`
  @media (max-width: 775px) {
    font-size: 36px;
  }
`

const SubTitle = styled(Box)`
  @media (max-width: 775px) {
    font-size: 26px;
  }
`

const Section = styled(Box)`
  background: #131313;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (max-width: 1035px) {
    flex-direction: column;
  }
`

const BottomCta = () => {
  return (
    <Wrap justifyContent="space-between" alignItems="center" mt={155}>
      <Section maxWidth={1200} m="auto" width="100%">
        <Box>
          <Title fontSize={48}>
            Don't just wonder, <BoldBlue>give&nbsp;it&nbsp;a&nbsp;go!</BoldBlue>
          </Title>
          <SubTitle fontSize={36} mt={12}>
            Enjoy a <BoldBlue>7-day&nbsp;trial</BoldBlue> to&nbsp;discover all our
            features.
          </SubTitle>
        </Box>
        <TryButtonStyled />
      </Section>
    </Wrap>
  )
}

export default BottomCta
