import { api } from '../api/index'
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query'
import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'

type QueryKeyT = [string, object | undefined]

export const fetcher = <T>(
  { queryKey }: QueryFunctionContext<QueryKeyT>,
  auth?: string,
): Promise<T> => {
  const [url, params] = queryKey
  // @ts-ignore
  return api.get<T>(url, auth, params)
}

export const useFetch = <T>(
  url: string | null,
  auth?: string,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
) => {
  const context = useQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => fetcher({ queryKey, meta: undefined }, auth),
    {
      enabled: !!url,
      ...config,
    },
  )

  return context
}

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined,
) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async data => {
      await queryClient.cancelQueries([url!, params])

      const previousData = queryClient.getQueryData([url!, params])

      queryClient.setQueryData<T>([url!, params], oldData => {
        return updater ? updater(oldData!, data as S) : (data as T)
      })

      return previousData
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context)
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params])
    },
  })
}

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T,
) => {
  return useGenericMutation<T, string | number>(
    id => api.delete(`${url}/${id}`),
    url,
    params,
    updater,
  )
}

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>(
    data => api.post<S>(url, data as Partial<S>),
    url,
    params,
    updater,
  )
}

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T,
) => {
  return useGenericMutation<T, S>(
    data => api.patch<S>(url, data as Partial<S>),
    url,
    params,
    updater,
  )
}
