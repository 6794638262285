import React from 'react'
import styled from 'styled-components'
import Box, { BoxProps } from '@ui/Box'
import routes from '@routes'
import Link from 'next/link'

const NoCard = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
`

const Btn = styled(Link)`
  display: inline-block;
  border-radius: 10px;
  background: #40e0ec;
  padding: 22px 40px;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;

  &:before {
    content: '';
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }

  &:active:after {
    background: transparent;
  }

  &:hover:before {
    opacity: 1;
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }
    50% {
      background-position: 400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`

interface Props extends BoxProps {
  className?: string
  onClick?: () => void
  noMessage?: boolean
  label?: React.ReactNode
  message?: string
  href?: string
  prefetch?: boolean
  target?: string
}

const TryButton = ({
  className,
  onClick,
  noMessage,
  message = 'No registration required',
  label = <>Try for free</>,
  href = routes.privateRoutes.builder.templates.replace(':id', 'demo'),
  prefetch = true,
  target,
  ...props
}: Props) => {
  return (
    <>
      <Box mt={100} className={className} {...props}>
        <Btn onClick={onClick} href={href} prefetch={prefetch} target={target}>
          {label}
        </Btn>
        {!noMessage && <NoCard>{message}</NoCard>}
      </Box>
    </>
  )
}

export default TryButton
