import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'

const client = axios.create({
  withCredentials: true,
})

async function getData(url: RequestInfo | URL, params: RequestInit) {
  const res = await fetch(url, { ...params, credentials: 'include' })
  // The return value is *not* serialized
  // You can return Date, Map, Set, etc.

  // Recommendation: handle errors
  if (!res.ok) {
    // This will activate the closest `error.js` Error Boundary
    throw new Error(`Failed to fetch data: ${url}`)
  }

  return res.json()
}

export const getApiEndpoint = () => {
  return process.env.NEXT_PUBLIC_API_URL
}

export const api = {
  get: <T>(
    route: string,
    auth?: string,
    params?: Record<string, string>,
    config?: RequestInit,
  ): Promise<T> => {
    return getData(`${getApiEndpoint()}${route}?${new URLSearchParams(params)}`, {
      headers: {
        Cookie: `Authentication=${auth}`,
      },
      ...config,
    })
  },
  post: <T, S = T>(
    route: string,
    data?: Partial<S>,
    config?: AxiosRequestConfig,
    auth?: string,
  ): AxiosPromise<T> => {
    return client.post<T>(`${getApiEndpoint()}${route}`, data, {
      headers: {
        Cookie: `Authentication=${auth}`,
      },
      ...config,
    })
  },
  patch: <T, S = T>(
    route: string,
    data?: Partial<S>,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> => {
    return client.patch<T>(`${getApiEndpoint()}${route}`, data, {
      ...config,
    })
  },
  put: <T, S = T>(
    route: string,
    data?: Partial<S>,
    config?: AxiosRequestConfig,
  ): AxiosPromise<T> => {
    return client.put<T>(`${getApiEndpoint()}${route}`, data, {
      ...config,
    })
  },
  delete: <T = any>(route: string, config?: AxiosRequestConfig): AxiosPromise<T> => {
    return client.delete<T>(`${getApiEndpoint()}${route}`, {
      ...config,
    })
  },
}
