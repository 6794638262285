import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { Color } from '../../interfaces/designSystem'
import Flex from './Flex'
import upperFirst from 'lodash/upperFirst'

type Props = {
  error?: boolean
  message?: React.ReactNode
}

const InputMessageBottom = ({ error, message }: Props) => {
  if (!message) {
    return null
  }

  return (
    <Flex
      alignItems="center"
      fontSize={12}
      color={error ? Color.Red : Color.Grey_50}
      mt={8}
    >
      {error && (
        <>
          <AiOutlineExclamationCircle />
          &nbsp;
        </>
      )}
      {typeof message === 'string' ? upperFirst(message) : message}
    </Flex>
  )
}

export default InputMessageBottom
