import React, { useState } from 'react'
import Box from '@ui/Box'
import TryButton from '@components/Landing/TryButton'
import Input from '@ui/Input'
import { collectGuestEmail } from '@api/users'
import { useCookies } from 'next-client-cookies'
import Flex from '@ui/Flex'
import styled from 'styled-components'
import { Cookies } from '@interfaces/common'

const NoCard = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
`

const FlexStyled = styled(Flex)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

const BoxStyled = styled(Box)`
  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 16px;
  }
`

const HeadCTA = () => {
  const [email, setEmail] = useState<string>()
  const cookies = useCookies()
  const promo = cookies.get('promo')
  const ref = cookies.get('ref')

  return (
    <Box mt={100}>
      <FlexStyled justifyContent="center" height="100%">
        <BoxStyled maxWidth={500} width="100%" mr={16} height="100%">
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            boxProps={{ height: '66px' }}
            style={{
              borderRadius: '10px',
              height: '100%',
              fontSize: '18px',
            }}
            placeholder="Enter your email"
          />
        </BoxStyled>
        <TryButton
          onClick={async () => {
            if (email) {
              cookies.set(Cookies.guest_email, email)
              await collectGuestEmail(email, promo, ref)
            }
          }}
          mt={0}
          noMessage
        />
      </FlexStyled>
      <NoCard>No credit card required</NoCard>
    </Box>
  )
}

export default HeadCTA
