import React from 'react'
import Box from '../UI/Box'

type Props = {
  children: any
}

const InputLabel = ({ children }: Props) => {
  return (
    <Box pb={6} fontSize={14}>
      {children}
    </Box>
  )
}

export default InputLabel
