import styled from 'styled-components'
import Box from '@ui/Box'

export const BoldBlue = styled.strong<{ $shadow?: boolean; $underlined?: boolean }>`
  color: #40e0ec;
  text-decoration: ${({ $underlined }) => ($underlined ? 'underline' : 'none')};
`

export const Content = styled(Box)`
  width: 98%;
  margin: auto;
  position: relative;
`
