import { FormikValues } from 'formik'

export const getInputProps = (form: FormikValues, name: string) => {
  const error = form.errors?.[name]
  return {
    ...form.getFieldProps(name),
    error: !!error,
    message: error,
  }
}
