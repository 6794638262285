import React from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import NextHead from 'next/head'
import HeadCTA from '@components/Landing/HeadCTA'

const Wrap = styled(Box)`
  margin-top: 200px;

  @media (max-width: 768px) {
    margin-top: 100px;
  }
`

const Title = styled.h2`
  font-size: 70px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 50px;
  }
`

const Content = styled.div``

const Video = styled.iframe`
  max-width: 1200px;
  width: 100%;
  height: 615px;
  margin-top: 32px;
  border-radius: 30px;
`

const WhyWeComponent = () => {
  return (
    <>
      <NextHead>
        <link rel="preload" href="/img/landing/ab.png" as="image" />
        <link rel="preload" href="/img/landing/modern-design.png" as="image" />
        <link rel="preload" href="/img/landing/analytics.png" as="image" />
      </NextHead>
      <Wrap id="how-it-works">
        <Title>How does it work?</Title>

        <Content>
          <Video
            width="560"
            height="315"
            src="https://www.youtube.com/embed/dcIOSuEz6NY?si=GW2Dvzwq2_M9S9qv"
            title="Create pricing table in 5 minutes with Enhanci"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          />
        </Content>
        <HeadCTA />
      </Wrap>
    </>
  )
}

export default WhyWeComponent
